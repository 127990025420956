import React                     from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import AppLayout                 from "../components/layouts/AppLayout";

function RouteInit() {
    return (
        <Router>
            <AppLayout/>
        </Router>
    );
}

export default RouteInit;