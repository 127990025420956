const baseRoute = '/';
//const baseApi                  = 'https://uatapi.sugamah.com/';
const baseApi = 'https://telemedicineapi.sysmedac.com:8089/';
const videoCallServer = 'https://televideocall.sysmedac.com/'

export const ROUTES = {
    HOME: baseRoute,
    LOGIN: baseRoute + 'login',
    LOGOUT: baseRoute + 'logout',
    DISCOUNT_MANAGEMENT: baseRoute + 'discount-promotions',
    CountryMaster: baseRoute + 'CountryMaster',
    ServiceProviderApproval: baseRoute + 'ServiceProviderApproval',
    AgentApproval: baseRoute + 'AgentApproval',
    CONSULTATION: baseRoute + 'consultation',
    DISCOUNT: baseRoute + 'discountmaster',
    Subscription: baseRoute + 'Subscription',
    Subscriptioncoverage: baseRoute + 'Subscriptioncoverage',
    Subscriptionreport: baseRoute + 'Subscriptionreport',
    Medicine: baseRoute + 'medicine',
    Configurationservice: baseRoute + 'Configurationservice',
    ConfigurationNotification: baseRoute + 'ConfigurationNotification',
    Language: baseRoute + 'Language',
    User: baseRoute + 'userdetails',
    Policy: baseRoute + 'Policy',
    Termssetup: baseRoute + 'Termssetup',
    Agentperformance: baseRoute + 'Agentperformance',
    ConsultationMaster: baseRoute + 'ConsultationMaster',
    Referral: baseRoute + 'Referral',
    Serviceperformance: baseRoute + 'Serviceperformance',

    DoctorMaster                : baseRoute+'DoctorMaster',
    DoctorAbsence               : baseRoute+'DoctorAbsence',
    DoctorShedule               : baseRoute+'DoctorShedule',
    bill                        : baseRoute+'Bill',
    AccountPayable              : baseRoute+'AccountPayable',
    Receivable                  : baseRoute+'AccountReceivable',
    DASHBOARD                   : baseRoute + 'Dashboard',
    AccountPayableagent              : baseRoute+'AccountPayableagent',
    PharmacyUtilization              : baseRoute+'PharmacyUtilization',
    PharmacyBusiness            : baseRoute+'PharmacyBusiness',
    PharmacyOustanding          : baseRoute+'PharmacyOustanding',
    LabBusiness                 : baseRoute+'LabBusiness',
    LabCommission               : baseRoute+'LabCommission',
    HospitalBusiness            : baseRoute+'HospitalBusiness',
    HospitalCommission          : baseRoute+'HospitalCommission',
    AgentCommission             : baseRoute+'AgentCommission',

    API: {
        ServiceProviderList: baseApi + 'AgentApproval/getServicePortaldetail',
        PharmacyDetailsForApprove: baseApi + 'AgentApproval/getPharmacyDetailsForApprove',
        LabDetailsForApprove: baseApi + 'AgentApproval/getLabDetailsForApprove',
        HospitalDetailsForApprove: baseApi + 'AgentApproval/getHospitalDetailsForApprove',
        PharmacyDocumentsForApprove: baseApi + 'AgentApproval/getPharmacyDocumentsForApprove',
        LabDocumentsForApprove: baseApi + 'AgentApproval/getLabDocumentsForApprove',
        HospitalDocumentsForApprove: baseApi + 'AgentApproval/getHospitalDocumentsForApprove',
        UpdateAgentStatus: baseApi + 'AgentApproval/UpdateAgentApproveStatus',
        UpdateLapStatus: baseApi + 'AgentApproval/UpdateLabApproveStatus',
        UpdateHospitalStatus: baseApi + 'AgentApproval/UpdateHospitalApproveStatus',
        UpdatePharmacyStatus: baseApi + 'AgentApproval/UpdatePharmacyApproveStatus',
        AgentsList: baseApi + 'AgentApproval/getAgentDetails',
        AgentsDetaisForApprove: baseApi + 'AgentApproval/getAgentDetailsForApprove',
        SubAgentsDetaisForApprove: baseApi + 'AgentApproval/getSubAgentDetailsForApprove',
        AgentDocumentsForApprove: baseApi + 'AgentApproval/getAgentDocumentsForApprove',
        SubAgentDocumentsForApprove: baseApi + 'AgentApproval/getSubAgentDocumentsForApprove',
        UpdateSubAgentStatus: baseApi + 'AgentApproval/UpdateSubAgentApproveStatus',
        ExportServiceProviderToExcel: baseApi + 'AgentApproval/expoerServicePortalToExcel',
        ExportServiceProviderToPDF: baseApi + 'AgentApproval/ServicePortalExportToPDF',
        ExportAgentToExcel: baseApi + 'AgentApproval/expoerAgentToExcel',
        ExportAgentToPDF: baseApi + 'AgentApproval/AgentExportToPDF',
        DoctorLogin: baseApi + 'Consultation/getDoctorLoginDetails',
        AdminLogin: baseApi + 'Consultation/getAdminLoginDetails',  
        Update_Admin_Profile_Password: baseApi + 'Consultation/changeAdminPassword',
        DownloadAgentFile: baseApi + 'AgentApproval/downloadAgentFile',
        DownloadSubAgentFile: baseApi + 'AgentApproval/downloadSubAgentFile',
        DownloadLabFile: baseApi + 'AgentApproval/downloadLabFile',
        DownloadHospitalFile: baseApi + 'AgentApproval/downloadHospitalFile',
        DownloadPharmacyFile: baseApi + 'AgentApproval/downloadPharmacyFile',
        UPDATEDOCTORSTATUSTOAVAILABLE:baseApi+ 'Consultation/updateDoctorStatusToAvailable',

        AVGenerateToken: videoCallServer + 'token/',
        AVcheckCallFromDoctor: baseApi + 'Consultation/checkCallFromDoctor',
        AVupdateDoctorStatusToAvailable: baseApi + 'Consultation/updateDoctorStatusToAvailable',
        AVupdateCallStatus: baseApi + 'Consultation/updateCallStatus',

        AVGetPatientDetailsById: baseApi + 'patient/getPatientById',
        AVGetPatientProfile: baseApi + 'auth/viewFile',
        AVGetPatientFatherProfile: baseApi + 'auth/viewFile',
        AVGetPatientMotherProfile: baseApi + 'auth/viewFile',
        AVGetPatientAddressById: baseApi + 'patient/getPatientAddressById',
        AVGetGetAllCheifComplaints: baseApi + 'Consultation/getAllChiefComplaintsForDDL',
        AVGSaveCheifComplaints: baseApi + 'Consultation/savePatientChiefComplaints',
        AVGsaveRelevantPoint: baseApi + 'Consultation/saveRelevantPoint',
        AVGsavePatientExamination: baseApi + 'Consultation/savePatientExamination',
        AVGsavePatientDiagnosis: baseApi + 'Consultation/savePatientDiagnosis',
        AVGsavePatientInstructions: baseApi + 'Consultation/savePatientInstructions',
        AVGgetNearestPharmacyDetails: baseApi + 'Consultation/getNearestPharmacyDetails',
        AVGgetNearestLabDetails: baseApi + 'Consultation/getNearestLabDetails',
        AVGetPatientDeliveryAddressById: baseApi + 'patient/getPatientDeliveryAddressById',
        getNearestHospitalDetails: baseApi + 'Consultation/getNearestHospitalDetails',
        AVInsertPharmacyOrder: baseApi + 'Consultation/InsertPharmacyOrders',
        AVInsertPharmacyOrderItems: baseApi + 'Consultation/InsertPharmacyOrdersItems',
        AVInsertLabOrders: baseApi + 'Consultation/InsertLabOrders',
        AVInsertLabOrdersItems: baseApi + 'Consultation/InsertLabOrdersItems',
        AVInsertPrescription: baseApi + 'Consultation/InsertPrescription',
        AVInsertHospitalReferal: baseApi + 'Consultation/InsertHospitalReferal',
        AVgetPatientDocumentsById: baseApi + 'Consultation/getPatientDocumentsById',
        AVDownloadDocument: baseApi + 'patient/downloadPatientDocument',
        AVGetOldPrescription: baseApi + 'Consultation/GetOldPrescriptions',
        AVGeneratePrescriptionPDF: baseApi + 'DoctorDetails/doctorprescriptionTele',
        AVDownloadPrescriptionPDF: baseApi + 'DoctorDetails/downloadPrescriptionFile',

        CON_MASTER_SAVE: baseApi + 'Consultation/saveChiefComplaints',
        CON_MASTER_GETALL: baseApi + 'Consultation/getAllChiefComplaints',
        CON_MASTER_UPDATE: baseApi + 'Consultation/updateChiefComplaints',
        Get_SubscriptionSetUpList: baseApi + 'Subscription/getSubscriptionSetupList',
        INSERT_SubParent: baseApi + 'Subscription/insertSubParent',
        INSERT_Subchild: baseApi + 'Subscription/insertSubChild',

        CONSULTATIONMASTEREXPORTTOPDF: baseApi + 'Consultation/ConsultationMasterExportToPDF',
        CONSULTATIONMASTEREXPORTTOEXCEL: baseApi + 'Consultation/ConsultationMasterExportToExcel',


        Create_Notification: baseApi + 'Notification/CreateNotification',
        Insert_Notification: baseApi + 'Notification/InsertMessage',
        Update_Notification: baseApi + 'Notification/updateNotification',
        GET_All_Notifications: baseApi + 'Notification/getlist',
        NOTIFICATION_EXPORT_EXCEL: baseApi + 'Notification/exportNotificationToExcel',
        NOTIFICATION_EXPORT_PDF: baseApi + 'Notification/exportNotificationToPDF',
        GET_ALL_ScreenNames: baseApi + 'Language/getAllScreenNames',
        GET_By_ScreenName: baseApi + 'Language/getbyScreenNames',
        GET_ALL_Languages: baseApi + 'Language/getAlllanguges',
        GET_ModifiedScreenNames: baseApi + 'Language/getModifiedScreenNames',
        InsertNewLabels: baseApi + 'Language/insertNewLabels',
        updateLabels: baseApi +'Language/updateLabel',
        LANGUAGE_EXPORT_EXCEL: baseApi +'Language/LanguageExportToExcel',
        LANGUAGE_EXPORT_PDF: baseApi +'Language/LanguageExportToPDF',
        Discount_Save_New: baseApi + 'Discount/saveNew',
        Discount_GET_All: baseApi + 'Discount/GetAllDetails',
        GET_BILL_BYTYPE: baseApi + 'Bill/getbyBillType',
        GET_BILL_BYID: baseApi + 'Bill/getbyBillId',
        DOWNLOADE_BILL: baseApi + 'Bill/downloadBILLFile',
        EXPORT_BILL_EXCEL: baseApi + 'Bill/BillExportexcel',
        EXPORT_BILL_PDF: baseApi + 'Bill/BillExportPDF',
        GET_SEARCH_BILL:baseApi+'Bill/searchbill',
        DOWNLOADE_BILLVIEW: baseApi + 'PatientBill/downloadBILLFile',

        updateDoctorStatusToAvailable: baseApi + 'Consultation/updateDoctorStatusToAvailable',
        GETALLMEDICINE: baseApi + 'Consultation/getAllMedicinesForDDL',
        EXPORT_USER_PDF:baseApi+'User/exportUserToPDF',
        EXPORT_USER_EXCEL:baseApi+'User/exportUserToExcel',
SUBSCRIPTIONCOVERAGES:{

    GETALLCOVERAGESNAME: baseApi  +'coverages/getAllCoverageNames',
    INSERTCOVERAGES: baseApi +'coverages/insertCoverages',
    UPDATECOVERAGES: baseApi +'coverages/updateCoverages',
    GETCOVERAGESID: baseApi+'coverages/getCoverageId',
    GETCOVGNAME: baseApi+'coverages/GetCoveragename',
    GETLANGLIST: baseApi+'languages/getlanguagelist',
    GETALLCOVERAGES: baseApi+'coverages/getAllCoverage',
    SUB_EXPORT_EXCEL: baseApi+'Subscription/SubscriptionExportToExcel',
    SUB_EXPORT_PDF: baseApi+'Subscription/SubscriptionExportToPDF',
    SUB_COV_EXPORT_EXCEL: baseApi+'coverages/CoverageToExcel',
    SUB_COV_EXPORT_PDF: baseApi+'coverages/CoverageExportToPDF',
},

        DOCTOR: {
            GET_QUALIFICATION: baseApi + 'DoctorDetails/getqualification',
            GET_ALL_DOCTOR: baseApi + 'DoctorDetails/getAll',
            SAVE_DOCTOR: baseApi + 'DoctorDetails/saveDoctorDetails',
            UPDATE_DOCTOR: baseApi + 'DoctorDetails/UpdateDoctorDetails',
            GETALLSSCHDULELIST:baseApi+'DoctorDetails/getAllShedulelist',
            GETSTATENAME:baseApi+'DoctorDetails/getstatename',
            SAVEDOCTORSCHEDULE:baseApi+ 'DoctorDetails/saveDoctorShedule',
            UPDATESCHEDULES:baseApi+'DoctorDetails/updateDoctorShedule',
           GETBYSTATE:baseApi+ 'DoctorDetails/getbyState',
           GETDOCTORLIST:baseApi+'DoctorDetails/getdoctorlist',
           DOCTORMASTEREXPORTTOPDF:baseApi+'DoctorDetails/DoctorMasterExportToPDF',
           DOCTORMASTEREXPORTTOEXCEL:baseApi+'DoctorDetails/DoctorMasterExportToExcel',
           DOCTORABSENTEXPORTTOPDF:baseApi+'DoctorDetails/DoctorAbsentExportToPDF',
           DOCTORABSENTEXPORTTOEXCEL:baseApi+'DoctorDetails/DoctorAbsentExportToExcel',
           DOCTORSCHEDULEEXPORTTOPDF:baseApi+'DoctorDetails/DoctorSheduleExportToPDF',
           DOCTORSCHEDULEEXPORTTOEXCEL:baseApi+'DoctorDetails/DoctorSheduleExportToExcel',
           REFERRALEXPORTTOPDF:baseApi+'DoctorDetails/ReferralExportToPDF',
           REFERRALEXPORTTOEXCEL:baseApi+'DoctorDetails/ReferralExportToExcel',
           BILLEXPORTTOPDF: baseApi+'Bill/BillExportPDF',
           BILLEXPORTTOEXCEL:baseApi+'Bill/BillExportexcel',
            GET_MISSED_CALL_DETAILS:baseApi+'Consultation/GetMissedCallDetailsForDoctor',
            UPDATE_MISSED_CALL_DETAILS:baseApi+'Consultation/updateMissedCallDetail',





        },
        DASHBOARD: {
            GET_PHARMACY_COUNT: baseApi + 'Dashboard/GetPharmacyCount',
            GET_BILL_AMOUNT: baseApi + 'Dashboard/GetBillAmount',
            GET_AGENT_DETAILS: baseApi + 'Dashboard/GetAgentDetails',
            GET_MEMBER_DETAILS: baseApi + 'Dashboard/GetMemberCount',
            GET_CALL_COUNT: baseApi + 'Dashboard/GetCallCount',
        },
        REPORTS:{
            GET_PHARMACY_UTILIZATION:baseApi+'SugamahReports/GetPharmacyUtilizationDetails',
            GET_AGENT_COMMISSION:baseApi+'SugamahReports/GetAgentSubAgent',
            AGENT_EXPORT_PDF: baseApi+'SugamahReports/AgentSubAgentExportToPDF',
            AGENT_EXPORT_EXCEL: baseApi+'SugamahReports/AgentSubAgentExportToExcel',
            GET_HOSPITAL_BUSINESS:baseApi+'SugamahReports/GetHospitalBusiness',
            GET_HOSPITAL_COMMISSION:baseApi+'SugamahReports/getHospitalCommissionReceivable',
            GET_LAB_COMMISSION:baseApi+'SugamahReports/GetLabCommissionReceivable',
            GET_LAB_BUSINESS:baseApi+'SugamahReports/GetLabsBusiness',
            GET_PHARMACY_BUSINESS:baseApi+'SugamahReports/GetPharmacyBusiness',
            GET_PHARMACY_OUTSTANDING:baseApi+'SugamahReports/GetPharmacyOustanding',
            HOSPITAL_BUSINESS_EXPORT_PDF: baseApi+'SugamahReports/HospitalBusinessExportToPDF',
            HOSPITAL_COMMISSION_EXPORT_PDF: baseApi+'SugamahReports/HospitalCommissionExportToPDF',
            LAB_BUSINESS_EXPORT_PDF: baseApi+'SugamahReports/LabBusinessExportToPDF',
            LAB_COMMISSION_EXPORT_PDF: baseApi+'SugamahReports/LabCommissionExportToPDF',
            PHARMACHY_BUSINESS_EXPORT_PDF: baseApi+'SugamahReports/PharmacyBusinessExportToPDF',
            PHARMACHY_OUTSTANDING_EXPORT_PDF: baseApi+'SugamahReports/PharmacyOustandingExportToPDF',
            PHARMACHY_UTILIZATION_EXPORT_PDF: baseApi+'SugamahReports/PharmacyUtilizationExportToPDF',
            HOSPITAL_BUSINESS_EXPORT_Excel: baseApi+'SugamahReports/HospitalBusinessExportToExcel',
            HOSPITAL_COMMISSION_EXPORT_Excel: baseApi+'SugamahReports/HospitalCommissionExportToExcel',
            LAB_BUSINESS_EXPORT_Excel: baseApi+'SugamahReports/LabBusinessExportToExcel',
            LAB_COMMISSION_EXPORT_Excel: baseApi+'SugamahReports/LabCommissionExportToExcel',
            PHARMACHY_BUSINESS_EXPORT_Excel: baseApi+'SugamahReports/PharmacyBusinessExportToExcel',
            PHARMACHY_OUTSTANDING_EXPORT_Excel: baseApi+'SugamahReports/PharmacyOustandingExportToExcel',
            PHARMACHY_UTILIZATION_EXPORT_Excel: baseApi+'SugamahReports/PharmacyUtilizationExportToExcel',
        },
        NOTIFICATION:{
            SEND_NOTIFICATION: baseApi+'notification/send-notification',
        },
    }

};

