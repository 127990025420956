import {useEffect} from "react";
import NProgress   from "nprogress";

export const LazyLoad = () => {
    useEffect(() => {
        NProgress.start();
        return () => {
            NProgress.done();
        };
    });
    return null;
};

interface propString {
    withNumber?: boolean;
    withSpace?: boolean;
    withDash?: boolean;
    withForwardSlash?: boolean;
    withComma?:boolean;
}

export const validateString = (string: string | null | undefined, config?: propString): boolean => {
    const _configDefault: propString = {
        withNumber      : false,
        withDash        : false,
        withForwardSlash: false,
        withSpace       : true,
        withComma       : false,
    };
    config                           = {..._configDefault, ...config};
    let patternStr                   = "";
    let isValid                      = true;

    if (config.withNumber) {
        patternStr += "0-9";
    }

    if (config.withDash) {
        patternStr += "-";
    }

    if (config.withForwardSlash) {
        patternStr += "/";
    }

    if (config.withSpace) {
        patternStr += "\\s";
    }
    if (config.withComma) {
        patternStr += ",";
    }
    
    const pattern = new RegExp("^[a-zA-Z" + patternStr + "]+$", 'gi');
    string        = string || '';

    if (!pattern.test(string)) {
        isValid = false;
    }

    return isValid;
};