import React, {lazy, Suspense,useState,useEffect}      from 'react';
import style                        from './style.module.scss';
import clsx                         from 'clsx';
import {useStateValue}              from "../../services/StateProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import RouteApp                     from "../../routes/RouteApp";
import {LazyLoad}                   from "../../services/HelperSections";

let theme = createTheme({
                            palette   : {
                                primary  : {
                                    main        : '#331C8D',
                                    contrastText: '#ffffff'
                                },
                                secondary: {
                                    main        : '#941c39',
                                    contrastText: '#ffffff'
                                }
                            },
                            typography: {
                                button: {
                                    textTransform: 'none'
                                }
                            }
                        });

const SideNavBar   = lazy(() => import('./includes/SideNavBar'));
const HeaderNavBar = lazy(() => import('./includes/HeaderNavBar'));

function AppLayout() {
    const [{user}]: any = useStateValue();
    const [_toggleSidebar, _setToggleSidebar] = useState<boolean>(false);
    useEffect(() => {
        if(window.innerWidth <= 760)
        {
            _setToggleSidebar(true);
        }
    },[]);
    const setToggleSidebar = (status: boolean) => {
        _setToggleSidebar(!_toggleSidebar);
    }

    return (<>
        {user && <Suspense fallback={null}>
                <SideNavBar toggleSidebar={_toggleSidebar}/>
            </Suspense>
        }
        <main className={clsx({[style.contentPage]: user}, {[style.contentPageFull]: _toggleSidebar})}>
            {user && <Suspense fallback={null}>
                <HeaderNavBar toggleSidebarUpdates={setToggleSidebar}/>
            </Suspense>}

            <div className={clsx(user && 'container-fluid')} style={{padding:0}}>
                <Suspense fallback={<LazyLoad/>}>
                    <ThemeProvider theme={theme}>
                        <RouteApp/>
                    </ThemeProvider>
                </Suspense>
            </div>
        </main>
    </>);
}

export default AppLayout;