

export const userSession = (withToken = false) => {
    
    const _token: string | null = localStorage.getItem('_token');

    if (_token === null ||  _token==="" ) {
        return null;
    }

    const user = JSON.parse(atob(_token));

    const response = {
        name        : user?.name,
        emailAddress: user?.emailAddress,
        accessToken : null
    };

    if (withToken) {
        response.accessToken = user?.accessToken;
    }

    return response;
};
export const validateNumber = (number: string | null | undefined, length: number = 10): boolean => {
    const pattern = new RegExp(/^[0-9\b]+$/);
    let isValid   = true;

    number = number || '';

    if (number.length !== length) {
        isValid = false;
    }

    if (!pattern.test(number)) {
        isValid = false;
    }

    return isValid;
};

interface propString {
    withNumber?: boolean;
    withSpace?: boolean;
    withDash?: boolean;
    withForwardSlash?: boolean;
    withClsoingBracket?:boolean;
    withComma?:boolean;
}

export const validateString = (string: string | null | undefined, config?: propString): boolean => {
    const _configDefault: propString = {
        withNumber      : false,
        withDash        : false,
        withForwardSlash: false,
        withSpace       : true,
        withClsoingBracket:false,
        withComma        :false,
    };
    config                           = {..._configDefault, ...config};
    let patternStr                   = "";
    let isValid                      = true;

    if (config.withNumber) {
        patternStr += "0-9";
    }

    if (config.withDash) {
        patternStr += "-";
    }

    if (config.withForwardSlash) {
        patternStr += "/";
    }
    if (config.withClsoingBracket) {
        patternStr += "(-)";
    }

    if (config.withSpace) {
        patternStr += "\\s";
    }

    if (config.withComma) {
        patternStr += ",";
    }

    const pattern = new RegExp("^[a-zA-Z" + patternStr + "]+$", 'gi');
    string        = string || '';

    if (!pattern.test(string)) {
        isValid = false;
    }

    return isValid;
};

