import React                   from 'react';
import ReactDOM                from 'react-dom';
import RouteInit               from './routes/RouteInit';
import {StateProvider}         from './services/StateProvider';
import Reducer, {initialState} from './services/StateReducer';

import './assets/scss/app.scss';

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <StateProvider initialState={initialState} reducer={Reducer}>
            <RouteInit/>
        </StateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
