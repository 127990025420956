import React, {lazy}             from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ROUTES}                  from '../configs/Constants';
import {useStateValue}           from '../services/StateProvider';

const Login                     = lazy(() => import('../pages/auth/Login'));
const Logout                    = lazy(() => import('../pages/auth/Logout'));
const CountryMster              = lazy(() => import('../pages/masters/CountryMaster'));
const ServiceProviderApproval   = lazy(() => import('../pages/serviceprovider/approval'));
const AgentApproval             = lazy(() => import('../pages/serviceprovider/AgentApproval'));
const Consultation              = lazy(() => import('../pages/Consultation'));
const Discountmaster            =lazy(()  => import('../pages/masters/discountmaster'));
const Subscriptioncoverage      =lazy(()=> import('../pages/masters/Subscriptioncoverage'));
const Subscription              =lazy(()  => import('../pages/masters/Subscription'));
const Subscriptionreport        =lazy(()  => import('../pages/masters/Subscriptionreport'));
const Medicinemaster            =lazy(()  => import('../pages/masters/medicine'));
const configurationservice      =lazy(()=> import('../pages/masters/Configurationservice'));
const configurationnotifiction  =lazy(()=> import('../pages/masters/ConfigurationNotification'));
const language                  =lazy(()=>import('../pages/masters/Language'));
const user                      =lazy(()=>import('../pages/masters/userdetails'));
const policy                    =lazy(()=>import('../pages/masters/Policy'));
const Terms                     =lazy(()=>import('../pages/masters/Termssetup'));
const DoctorMster               =lazy(()=>import('../pages/masters/DoctorMaster'));
const Doctorabsen               =lazy(()=>import('../pages/masters/DoctorAbsence'));
const Doctorshedul              =lazy(()=>import('../pages/masters/DoctorShedule'));
const agentperformance          =lazy(()=>import('../pages/masters/Agentperformance'));
const ConsultationMster         =lazy(()=>import('../pages/masters/ConsultationMaster'));
const Bill                      =lazy(()=>import('../pages/masters/Bill'));
const payable                   =lazy(()=>import('../pages/masters/AccountPayable'));
const receivable                =lazy(()=>import('../pages/masters/AccountReceivable'));
const Dashboard                 = lazy(() => import('../pages/Dashboard'));
const Referral                  = lazy(() => import('../pages/masters/Referral'));
const Serviceperformance        = lazy (()=>import("../pages/masters/Serviceperformance"));
const agentpayableagent         =lazy(()=>import('../pages/masters/AccountPayableagent'));
const PharmacyUtilization       =lazy(()=>import('../pages/Reports/PharmacyUtilization'));
const PharmacyBusiness          =lazy(()=>import('../pages/Reports/PharmacyBusiness'));
const PharmacyOustanding        =lazy(()=>import('../pages/Reports/PharmacyOustanding'));
const LabBusiness               =lazy(()=>import('../pages/Reports/LabBusiness'));
const LabCommission             =lazy(()=>import('../pages/Reports/LabCommission'));
const HospitalBusiness          =lazy(()=>import('../pages/Reports/HospitalBusiness'));
const HospitalCommission        =lazy(()=>import('../pages/Reports/HospitalCommission'));
const AgentCommission           =lazy(()=>import('../pages/Reports/AgentCommission'));

function RouteApp(): JSX.Element {
    return (
        <Switch>
            <PublicRoute path={ROUTES.LOGIN} component={Login}/>
            <PublicRoute path={ROUTES.LOGOUT} component={Logout}/>
            <PrivateRoute path={ROUTES.CountryMaster} component={CountryMster}/>
            <PublicRoute path={ROUTES.CONSULTATION} component={Consultation}/>
            <PublicRoute path={ROUTES.HOME} component={Login} exact/>
            <PrivateRoute path={ROUTES.DoctorMaster} component={DoctorMster} />
            <PrivateRoute path={ROUTES.DoctorAbsence} component={Doctorabsen} />
            <PrivateRoute path={ROUTES.DoctorShedule} component={Doctorshedul} />
            <PublicRoute path={ROUTES.ServiceProviderApproval} component={ServiceProviderApproval} exact/>
            <PublicRoute path={ROUTES.AgentApproval} component={AgentApproval} exact/>
            <PublicRoute path={ROUTES.DISCOUNT } component={Discountmaster} exact/>
            <PublicRoute path={ROUTES.Subscriptioncoverage } component={Subscriptioncoverage} exact/> 
            <PublicRoute path={ROUTES.Subscription } component={Subscription} exact/> 
            <PublicRoute path={ROUTES.Subscriptionreport } component={Subscriptionreport} exact/>
            <PublicRoute path={ROUTES.Medicine} component={Medicinemaster} exact/>
            <PublicRoute path={ROUTES.Configurationservice }component={configurationservice} exact /> 
            <PublicRoute path={ROUTES.ConfigurationNotification }component={configurationnotifiction} exact /> 
            <PublicRoute path={ROUTES.Language }component={language} exact /> 
            <PublicRoute path={ROUTES.User }component={user} exact /> 
            <PublicRoute path={ROUTES.Policy }component={policy} exact /> 
            <PublicRoute path={ROUTES.Termssetup }component={Terms} exact /> 
            <PublicRoute path={ROUTES.Agentperformance} component={agentperformance} exact/>

            <PrivateRoute path={ROUTES.ConsultationMaster} component={ConsultationMster}/>
            <PrivateRoute path={ROUTES.Referral} component={Referral}/>
            <PrivateRoute path={ROUTES.Serviceperformance} component={Serviceperformance}/>
            <PublicRoute path={ROUTES.AccountPayableagent} component={agentpayableagent} exact/>

           

            <PublicRoute path={ROUTES.bill}component={Bill}exact/>
            <PublicRoute path={ROUTES.AccountPayable} component={payable} exact/>
            <PublicRoute path={ROUTES.Receivable} component={receivable} exact/>
            <PublicRoute path={ROUTES.DASHBOARD} component={Dashboard} exact/>
            <PublicRoute path={ROUTES.PharmacyUtilization} component={PharmacyUtilization} exact/>
            <PublicRoute path={ROUTES.PharmacyBusiness} component={PharmacyBusiness} exact/>
            <PublicRoute path={ROUTES.PharmacyOustanding} component={PharmacyOustanding} exact/>
            <PublicRoute path={ROUTES.LabBusiness} component={LabBusiness} exact/>
            <PublicRoute path={ROUTES.LabCommission} component={LabCommission} exact/>
            <PublicRoute path={ROUTES.HospitalBusiness} component={HospitalBusiness} exact/>
            <PublicRoute path={ROUTES.HospitalCommission} component={HospitalCommission} exact/>
            <PublicRoute path={ROUTES.AgentCommission} component={AgentCommission} exact/>
        </Switch>
    );
}

const PrivateRoute: React.FC<any> = ({component: Component, ...rest}) => {
    const [{user}]: any = useStateValue();
    return (
        <Route {...rest} render={props => (
            user ?
            <Component {...props} />
                 : <Redirect to={ROUTES.LOGIN}/>
        )}/>
    );
};

const PublicRoute: React.FC<any> = ({component: Component, restricted, ...rest}) => {
    const [{user}]: any = useStateValue();
    return (
        <Route {...rest} render={props => (
            user && restricted ?
            <Redirect to={ROUTES.CountryMaster}/>
                               : <Component {...props} />
        )}/>
    );
};


export default RouteApp;